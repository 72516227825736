import React, { useState } from "react";
import { connect, useSelector } from "react-redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  ContactUsQuery,
  ContactUsFormQuery,
} from "../../prismic/staticQueries";
import Layout from "../../layout";
import {
  Subscription,
  BreadCrumbBanner,
  AddFormFields,
} from "../../components/common";
import { Form } from "react-bootstrap";
import { sendEmail } from "../../actions";
import { SUCCESS_MESSAGES } from "../../utils/constants";
import BANNER from "../../images/siteImages/contactUs/CONTACT_BANNER.jpg";
import PaymentSlider from "./PaymentSlider";

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/;

const schema = Yup.object({
  full_name: Yup.string().required("Full name is required"),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  phone_number: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  country: Yup.string().required("Country is required"),
  message: Yup.string().required("Message should not be left blank"),
});

const ContactUs = () => {
  const language = useSelector((state) => state.language);
  const contactUsData = ContactUsQuery(language);
  const contactUsFormData = ContactUsFormQuery(language);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const formSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    setIsLoading(true);
    setMessage(null);
    try {
      await sendEmail("Contact Us", values);
      resetForm({});
      setStatus({ success: true });
      setMessage(SUCCESS_MESSAGES.EMAIL_SENT[language]);
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message || "Unable to submit" });
    }
    setIsLoading(false);
  };
  return (
    <Layout>
      <BreadCrumbBanner
        banner_image={BANNER}
        heading={contactUsData.heading_title}
      />
      <div style={{ clear: "both" }}></div>
      <section className="contact-split align-items-center">
        <div className="row">
          <div className="col-lg-6 col-sm-6 col-xs-12 no-padding panel">
            <div className="g-maps">
              {/* <iframe
                title="iframe"
                className="map"
                src={contactUsData.map_url.text}
                width="100%"
                height="100%"
                frameBorder="0" 
                style={{ border: 0 }}
                allowFullScreen=""
              ></iframe> */}
              <div className="map-top col-lg-10 col-md-12">
                <ul className="icon-list--three">
                  {contactUsData.address.map((address, addressInd) => (
                    <li
                      className="icon-list2 d-flex"
                      key={"sdfsd" + addressInd}
                    >
                      <div className="icon">
                        <i className={address.address_icon.text}></i>
                      </div>
                      <div className="contents">
                        <h6>{address.address_title.text}</h6>
                        {address.address_descriptions.richText &&
                          address.address_descriptions.richText.map(
                            (line, lineInd) => (
                              <div key={"sdfsdfsdf" + lineInd}>
                                <span className="sub-text">{line.text}</span>
                                <br />
                                <span className="sub-text">{line.text1}</span>
                              </div>
                            )
                          )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12 no-padding panel">
            <div className="col-lg-8 col-md-10 col-sm-12 contact-form-page form-wrapper">
              <div className="m-bottom-10 mt-5">
                <div className="divider divider-simple">
                  <h3>{contactUsData.contact_us_form_title.text}</h3>
                </div>
              </div>

              <p className="m-bottom-30">
                {contactUsData.contact_us_form_description.text}
              </p>
              {message ? (
                <div className="alert alert-success">{message}</div>
              ) : (
                ""
              )}
              <Formik
                validationSchema={schema}
                onSubmit={formSubmit}
                initialValues={{
                  full_name: "",
                  email: "",
                  phone_number: "",
                  country: "",
                  message: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form id="contact-us-form" noValidate onSubmit={handleSubmit}>
                    <AddFormFields
                      data={contactUsFormData.body[0].items}
                      label="none"
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                    />
                    <Form.Group>
                      <button
                        className="btn"
                        disabled={isLoading || !isValid}
                        type="submit"
                        style={{
                          backgroundColor: "#1175bb",
                          color: "#fff",
                        }}
                      >
                        {contactUsFormData.submit_button_text.text}
                      </button>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <PaymentSlider/>
    </Layout>
  );
};

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(ContactUs);
